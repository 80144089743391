@import '../node_modules/bulma';

section{
    margin: 180px 0;
}

.box-image{
    border-radius: 20px;
    -webkit-box-shadow: 42px 69px 129px -44px rgba(97,97,97,1);
    -moz-box-shadow: 42px 69px 129px -44px rgba(97,97,97,1);
    box-shadow: 42px 69px 129px -44px rgba(97,97,97,1);
}

.scrolling-wrapper-flexbox {
    height: 45vh;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.title {
    margin: 10px 0;
}

.subtitle {
    font-size: 20px;
    line-height: 2.5rem;
    padding-top: 30px;
}

.hero {
    margin-top: 40px;
}

.hero-nav {
    margin-top: 30px;
}

.hero-title{
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
}

.hero-subtitle{
    font-size: 20px;
}

.button {
    margin: 3px 5px;
}

.tag{
    margin: 25px 5px 25px 0;
}

.title{
    font-size: 40px;
}

.footer{
    font-size: 20px;
    background-color: #fff;
}

.animated-card {
    width: 50ch;
    height: 75ch;
    background: grey;
    border-radius: 20px;
    background-image: url(https://images.unsplash.com/photo-1542080681-b52d382432af?ixlib=rb-1.2.1&w=1000&q=80);
    background-size: cover;
    background-position: center center;
    -webkit-box-shadow: 42px 69px 129px -44px rgba(97,97,97,1);
    -moz-box-shadow: 42px 69px 129px -44px rgba(97,97,97,1);
    box-shadow: 42px 69px 129px -44px rgba(97,97,97,1);
    transition: box-shadow 0.5s;
    will-change: transform;
}

.card {
    border-radius: 20px;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
}

.image > img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.navbar-brand{
    font-size: 20px;
}

//home card backgrounds
.yuca{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-image: url(https://raw.githubusercontent.com/juansinmiedos/juansinmiedos.com/master/public/prin-yuca.png);
    background-size: cover;
    background-position: center center;
}

.evee{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-image: url(https://raw.githubusercontent.com/juansinmiedos/juansinmiedos.com/master/public/prin-evee.png);
    background-size: cover;
    background-position: center center;
}

.aid{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-image: url(https://raw.githubusercontent.com/juansinmiedos/juansinmiedos.com/master/public/prin-%2Baid.png);
    background-size: cover;
    background-position: center center;
}